import classnames from 'classnames';
import { useCallback, useMemo } from 'react';
import { animated, config, useSpring } from '@react-spring/web';
import { useWindowSize } from '@/utils/hooks';
import Lock from '@/components/atoms/Lock';
import { route1, route2, route3 } from '@/utils/data';

import styles from './index.module.css';
import { map } from '@/utils/math';

const srcWidth = 1024;
const srcHeight = 1448;
const srcScale = 1;

const layerWidth = srcWidth * srcScale;
const layerHeight = srcHeight * srcScale;

type Stage = 'bottomUp' | 'floating' | 'enlarge' | 'select' | 'leaving';
type LandType = 1 | 2 | 3;

export interface LandProp {
    className?: string;
    display?: boolean;
    bottomUpProgress: number;
    floatingProgress: number;
    enlargeProgress: number;
    selectProgress: number;
    leavingProgress: number;
    activeLand: LandType;
    onSelectLand?: (land: LandType) => void;
}

const durationMap: Record<Stage, number | undefined> = {
    bottomUp: undefined,
    floating: 300,
    enlarge: 600,
    select: undefined,
    leaving: undefined,
};

const Land: React.FC<LandProp> = ({
    className,
    display,
    bottomUpProgress,
    floatingProgress,
    enlargeProgress,
    selectProgress,
    leavingProgress,
    activeLand,
    onSelectLand = () => {},
}) => {
    const { winWidth, winHeight } = useWindowSize();

    const stage = useMemo<Stage>(() => {
        if (leavingProgress > 0) {
            return 'leaving';
        }

        if (selectProgress > 0) {
            return 'select';
        }

        if (enlargeProgress > 0) {
            return 'enlarge';
        }

        if (floatingProgress > 0) {
            return 'floating';
        }

        return 'bottomUp';
    }, [floatingProgress, enlargeProgress, selectProgress, leavingProgress]);

    const entireLandXTarget = useMemo(() => {
        if (stage === 'enlarge' && winWidth > 600) {
            return map(enlargeProgress, 0, 100, 0, winWidth * -0.23);
        }

        return 0;
    }, [stage, winWidth, enlargeProgress]);

    const entireLandYTarget = useMemo(() => {
        let value = 0;
        const selectOffset = (winHeight / layerHeight) * -0.1;

        if (stage === 'leaving') {
            if (winWidth <= 600) {
                value = map(leavingProgress, 0, 100, -1.15 - selectOffset, -3);
            } else {
                value = map(leavingProgress, 0, 100, -0.22 - selectOffset, -1.2);
            }
        }

        if (stage === 'select') {
            if (selectProgress < 50) {
                if (winWidth <= 600) {
                    value = map(selectProgress, 0, 50, 0.4, 0.5);
                } else {
                    value = map(selectProgress, 0, 50, 0.35, 0.5);
                }
            } else {
                if (winWidth <= 600) {
                    value = map(selectProgress, 50, 100, 0.5, -1.15 - selectOffset);
                } else {
                    value = map(selectProgress, 50, 100, 0.5, -0.25 - selectOffset);
                }
            }
        }

        if (stage === 'enlarge') {
            if (winWidth <= 600) {
                value = map(enlargeProgress, 0, 100, -0.08, 0.4);
            } else {
                value = map(enlargeProgress, 0, 100, -0.08, 0.35);
            }
        }

        if (stage === 'floating') {
            value = map(floatingProgress, 0, 100, 0.33, -0.08);
        }

        if (stage === 'bottomUp') {
            if (winWidth <= 800) {
                const targetY = 1 - (winHeight - 100) / winHeight;
                value = map(bottomUpProgress, 0, 100, 1, targetY);
            } else {
                value = map(bottomUpProgress, 0, 100, 1, 0.33);
            }
        }

        return value;
    }, [
        stage,
        winWidth,
        winHeight,
        bottomUpProgress,
        floatingProgress,
        enlargeProgress,
        selectProgress,
        leavingProgress,
    ]);

    const entireLandScaleTarget = useMemo(() => {
        const baseScale = winHeight / layerHeight;
        let scale = 1;

        if (stage === 'leaving') {
            if (winWidth <= 600) {
                scale = map(leavingProgress, 0, 100, 0.5, 0.4);
            } else {
                scale = map(leavingProgress, 0, 100, 1, 0.9);
            }
        }

        if (stage === 'select') {
            if (winWidth <= 600) {
                scale = map(selectProgress, 0, 100, 1.2, 0.5);
            } else {
                scale = map(selectProgress, 0, 100, 1.8, 1);
            }
        }

        if (stage === 'enlarge') {
            if (winWidth <= 600) {
                scale = map(enlargeProgress, 0, 100, 1, 1.1);
            } else if (winWidth <= 1000) {
                scale = map(enlargeProgress, 0, 100, 1, 1.2);
            } else {
                scale = map(enlargeProgress, 0, 100, 1, 1.8);
            }
        }

        if (stage === 'floating') {
            scale = map(floatingProgress, 0, 100, 1.25, 1);
        }

        if (stage === 'bottomUp') {
            if (winWidth <= 800) {
                let scaleTarget = (winWidth / winHeight) * 1.5;
                scale = scaleTarget > 1.25 ? 1.25 : scaleTarget;
            } else {
                scale = 1.25;
            }
        }

        return baseScale * scale;
    }, [
        stage,
        winWidth,
        winHeight,
        floatingProgress,
        enlargeProgress,
        selectProgress,
        leavingProgress,
    ]);

    const land1Target = useMemo(() => {
        let x = 0;
        let y = 0;
        let scale = 1;

        if (stage === 'select') {
            let targetX = 0;
            let targetY = 0;
            let targetScale = 1;

            if (activeLand === 1) {
                targetX = 5;
                targetY = 0;
                targetScale = 1;
            } else if (activeLand === 2) {
                targetX = -30;
                targetY = 3;
                targetScale = 0.6;
            } else if (activeLand === 3) {
                targetX = 40;
                targetY = 5;
                targetScale = 0.55;
            }

            x = map(selectProgress, 0, 100, 0, targetX);
            y = map(selectProgress, 0, 100, 0, targetY);
            scale = map(selectProgress, 0, 100, 1, targetScale);
        }

        return [x, y, scale];
    }, [stage, activeLand, selectProgress]);

    const land1OpacityTarget = useMemo(() => {
        if (stage === 'select' && activeLand !== 1) {
            return map(selectProgress, 0, 100, 1, 0);
        }

        return 1;
    }, [stage, activeLand, selectProgress]);

    const land2Target = useMemo(() => {
        let x = 0;
        let y = 0;
        let scale = 1;

        if (stage === 'select') {
            let targetX = 0;
            let targetY = 0;
            let targetScale = 1;

            if (activeLand === 1) {
                targetX = 20;
                targetY = 0;
                targetScale = 0.85;
            } else if (activeLand === 2) {
                targetX = -18;
                targetY = -12;
                targetScale = 1.2;
            } else if (activeLand === 3) {
                targetX = -55;
                targetY = -1;
                targetScale = 0.7;
            }

            x = map(selectProgress, 0, 100, 0, targetX);
            y = map(selectProgress, 0, 100, 0, targetY);
            scale = map(selectProgress, 0, 100, 1, targetScale);
        }

        return [x, y, scale];
    }, [stage, activeLand, selectProgress]);

    const land2OpacityTarget = useMemo(() => {
        if (stage === 'select' && activeLand !== 2) {
            return map(selectProgress, 0, 100, 1, 0);
        }

        return 1;
    }, [stage, activeLand, selectProgress]);

    const land3Target = useMemo(() => {
        let x = 0;
        let y = 0;
        let scale = 1;

        if (stage === 'select') {
            let targetX = 0;
            let targetY = 0;
            let targetScale = 1;

            if (activeLand === 1) {
                targetX = -35;
                targetY = -6;
                targetScale = 0.86;
            } else if (activeLand === 2) {
                targetX = 40;
                targetY = -7;
                targetScale = 0.6;
            } else if (activeLand === 3) {
                targetX = 5;
                targetY = -18;
                targetScale = 1.3;
            }

            x = map(selectProgress, 0, 100, 0, targetX);
            y = map(selectProgress, 0, 100, 0, targetY);
            scale = map(selectProgress, 0, 100, 1, targetScale);
        }

        return [x, y, scale];
    }, [stage, activeLand, selectProgress]);
    const land3OpacityTarget = useMemo(() => {
        if (stage === 'select' && activeLand !== 3) {
            return map(selectProgress, 0, 100, 1, 0);
        }

        return 1;
    }, [stage, activeLand, selectProgress]);

    const land4OpacityTarget = useMemo(() => {
        if (stage === 'select') {
            return map(selectProgress, 0, 100, 1, 0);
        }

        return 1;
    }, [stage, selectProgress]);

    const {
        entireLandStyles,
        land1Pos,
        land2Pos,
        land3Pos,

        land1Opacity,
        land2Opacity,
        land3Opacity,
        land4Opacity,
    } = useSpring({
        entireLandStyles: [entireLandXTarget, entireLandYTarget, entireLandScaleTarget],
        land1Pos: land1Target,
        land1Opacity: land1OpacityTarget,

        land2Pos: land2Target,
        land2Opacity: land2OpacityTarget,

        land3Pos: land3Target,
        land3Opacity: land3OpacityTarget,

        land4Opacity: land4OpacityTarget,
        config:
            stage === 'leaving'
                ? {
                      easing: (x) => x,
                  }
                : {
                      ...config.slow,
                      duration: durationMap[stage],
                  },

        immediate: stage === 'leaving' && leavingProgress > 80,
    });

    const selectable = useMemo(() => {
        return stage === 'select' && selectProgress === 100;
    }, [stage, selectProgress]);

    const handleSelect = useCallback(
        (land: LandType) => {
            if (selectable) {
                onSelectLand(land);
            }
        },
        [onSelectLand, selectable],
    );

    return (
        <div className={classnames(styles.landRoot, display && styles.display, className)}>
            <div
                className={styles.emptyBase}
                style={{
                    width: layerWidth,
                    height: layerHeight,
                }}
            />

            <animated.div
                className={styles.landWrapper}
                style={{
                    width: layerWidth,
                    height: layerHeight * 0.92,
                    transform: entireLandStyles.to(
                        (x, y, scale) =>
                            `translate(${layerWidth * -0.5 + x}px, ${layerHeight * scale * y}px) scale(${scale})`,
                    ),
                }}
            >
                {/* land 1 愛情線 ------- */}
                <animated.div
                    className={styles.activeLand}
                    style={{
                        width: layerWidth,
                        height: layerHeight,
                        opacity: land1Opacity,
                        transform: land1Pos.to(
                            (x, y, scale) => `translate(${x}%, ${y}%) scale(${scale})`,
                        ),
                    }}
                >
                    <div className={styles.element}>
                        <img
                            src="/images/land-01.png"
                            alt="land"
                            width={layerWidth}
                            height={layerHeight}
                        />
                    </div>
                    <div className={styles.elementWrapper}>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-13-tree.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-12-grass.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-11-window.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-10-mirror.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-09-hospital.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-08-fountain.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-08-fountain.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-07-clouds.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-06-popo-stairs.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-05-pink-portal.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-04-silver-grass.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-03-plant.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-02-window.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-01-01-lights.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                    </div>
                    {route1.locked && (
                        <div
                            className={styles.element}
                            style={{
                                left: '28.5%',
                                top: '38%',
                            }}
                        >
                            <Lock active={selectable && activeLand === 1} twinkling />
                        </div>
                    )}
                </animated.div>
                <animated.div
                    className={styles.inactiveLand}
                    style={{
                        width: layerWidth,
                        height: layerHeight,
                        transform: land1Pos.to(
                            (x, y, scale) => `translate(${x}%, ${y}%) scale(${scale})`,
                        ),
                        opacity: land1Opacity.to((value) => 1 - value),
                    }}
                >
                    <div className={styles.element}>
                        <img
                            src="/images/land-01-inactive.png"
                            alt="element"
                            width={layerWidth}
                            height={layerHeight}
                        />
                    </div>
                    <div
                        style={{
                            transform: 'translate(0px, 100px) scale(1.65, 1.5)',
                            transformOrigin: 'left top',
                        }}
                    >
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 1 && styles.inactive,
                            )}
                            style={{
                                width: 400,
                                height: 160,
                                top: 200,
                                left: 70,
                            }}
                            onClick={() => {
                                handleSelect(1);
                            }}
                        />
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 1 && styles.inactive,
                            )}
                            style={{
                                width: 360,
                                height: 50,
                                top: 200,
                                left: 90,
                            }}
                            onClick={() => {
                                handleSelect(1);
                            }}
                        />
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 1 && styles.inactive,
                            )}
                            style={{
                                width: 320,
                                height: 50,
                                top: 200,
                                left: 115,
                            }}
                            onClick={() => {
                                handleSelect(1);
                            }}
                        />
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 1 && styles.inactive,
                            )}
                            style={{
                                width: 260,
                                height: 50,
                                top: 200,
                                left: 145,
                            }}
                            onClick={() => {
                                handleSelect(1);
                            }}
                        />
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 1 && styles.inactive,
                            )}
                            style={{
                                width: 180,
                                height: 50,
                                top: 200,
                                left: 175,
                            }}
                            onClick={() => {
                                handleSelect(1);
                            }}
                        />
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 1 && styles.inactive,
                            )}
                            style={{
                                width: 130,
                                height: 80,
                                top: 200,
                                left: 195,
                            }}
                            onClick={() => {
                                handleSelect(1);
                            }}
                        />
                    </div>
                </animated.div>

                {/* land 2 限時轉運站 ------- */}
                <animated.div
                    className={styles.activeLand}
                    style={{
                        width: layerWidth,
                        height: layerHeight,
                        transform: land2Pos.to(
                            (x, y, scale) => `translate(${x}%, ${y}%) scale(${scale})`,
                        ),
                        opacity: land2Opacity,
                    }}
                >
                    <div className={styles.element}>
                        <img
                            src="/images/land-02.png"
                            alt="element"
                            width={layerWidth}
                            height={layerHeight}
                        />
                    </div>
                    <div className={styles.elementWrapper}>
                        <div className={styles.element}>
                            <img
                                src="/images/land-02-11-palm-tree.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-02-10-table.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-02-09-time-station.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-02-08-deer.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-02-07-rocks.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-02-06-bricks.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-02-05-tree.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-02-04-hourglass.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-02-03-zodiac.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-02-02-snake.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-02-01-cloud.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                    </div>
                    {route2.locked && (
                        <div
                            className={styles.element}
                            style={{
                                left: '51.5%',
                                top: '46%',
                                transform: 'scale(0.8)',
                            }}
                        >
                            <Lock active={selectable && activeLand === 2} twinkling />
                        </div>
                    )}
                </animated.div>
                <animated.div
                    className={styles.inactiveLand}
                    style={{
                        width: layerWidth,
                        height: layerHeight,
                        transform: land2Pos.to(
                            (x, y, scale) => `translate(${x}%, ${y}%) scale(${scale})`,
                        ),
                        opacity: land2Opacity.to((value) => 1 - value),
                    }}
                >
                    <div className={styles.element}>
                        <img
                            src="/images/land-02-inactive.png"
                            alt="element"
                            width={layerWidth}
                            height={layerHeight}
                        />
                    </div>
                    <div
                        style={{
                            transform: 'translate(0px, 100px) scale(1.65, 1.5)',
                            transformOrigin: 'left top',
                        }}
                    >
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 2 && styles.inactive,
                            )}
                            style={{
                                width: 290,
                                height: 100,
                                top: 350,
                                left: 270,
                            }}
                            onClick={() => {
                                handleSelect(2);
                            }}
                        />
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 2 && styles.inactive,
                            )}
                            style={{
                                width: 260,
                                height: 100,
                                top: 350,
                                left: 290,
                            }}
                            onClick={() => {
                                handleSelect(2);
                            }}
                        />
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 2 && styles.inactive,
                            )}
                            style={{
                                width: 200,
                                height: 100,
                                top: 350,
                                left: 290,
                            }}
                            onClick={() => {
                                handleSelect(2);
                            }}
                        />
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 2 && styles.inactive,
                            )}
                            style={{
                                width: 100,
                                height: 120,
                                top: 350,
                                left: 290,
                            }}
                            onClick={() => {
                                handleSelect(2);
                            }}
                        />
                    </div>
                </animated.div>

                {/* land 3 共聲電台 ------- */}
                <animated.div
                    className={styles.activeLand}
                    style={{
                        width: layerWidth,
                        height: layerHeight,
                        transform: land3Pos.to(
                            (x, y, scale) => `translate(${x}%, ${y}%) scale(${scale})`,
                        ),
                        opacity: land3Opacity,
                    }}
                >
                    <div className={styles.element}>
                        <img
                            src="/images/land-03.png"
                            alt="element"
                            width={layerWidth}
                            height={layerHeight}
                        />
                    </div>
                    <div className={styles.elementWrapper}>
                        <div className={styles.element}>
                            <img
                                src="/images/land-03-12-waterfall.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-03-11-tree.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-03-10-fort.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-03-09-mountain.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-03-08-butterfly.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-03-07-lake.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-03-06-reef.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-03-05-moon.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-03-04-frog.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-03-03-flower.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-03-02-butterfly.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-03-01-butterfly.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                        <div className={styles.element}>
                            <img
                                src="/images/land-04.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </div>
                    </div>
                    {route3.locked && (
                        <div
                            className={styles.element}
                            style={{
                                left: '29%',
                                top: '52%',
                                transform: 'scale(0.8)',
                            }}
                        >
                            <Lock active={selectable && activeLand === 3} twinkling />
                        </div>
                    )}
                </animated.div>
                <animated.div
                    className={styles.inactiveLand}
                    style={{
                        width: layerWidth,
                        height: layerHeight,
                        transform: land3Pos.to(
                            (x, y, scale) => `translate(${x}%, ${y}%) scale(${scale})`,
                        ),
                        opacity: land3Opacity.to((value) => 1 - value),
                    }}
                >
                    <div className={styles.element}>
                        <img
                            src="/images/land-03-inactive.png"
                            alt="element"
                            width={layerWidth}
                            height={layerHeight}
                        />
                    </div>

                    <div
                        style={{
                            transform: 'translate(0px, 100px) scale(1.65, 1.5)',
                            transformOrigin: 'left top',
                        }}
                    >
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 3 && styles.inactive,
                            )}
                            style={{
                                width: 140,
                                height: 100,
                                top: 330,
                                left: 200,
                            }}
                            onClick={() => {
                                handleSelect(3);
                            }}
                        />
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 3 && styles.inactive,
                            )}
                            style={{
                                width: 260,
                                height: 100,
                                top: 330,
                                left: 140,
                            }}
                            onClick={() => {
                                handleSelect(3);
                            }}
                        />
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 3 && styles.inactive,
                            )}
                            style={{
                                width: 200,
                                height: 100,
                                top: 330,
                                left: 180,
                            }}
                            onClick={() => {
                                handleSelect(3);
                            }}
                        />
                        <div
                            className={classnames(
                                styles.selectArea,
                                selectable && styles.selectable,
                                activeLand === 3 && styles.inactive,
                            )}
                            style={{
                                width: 120,
                                height: 140,
                                top: 330,
                                left: 230,
                            }}
                            onClick={() => {
                                handleSelect(3);
                            }}
                        />
                    </div>
                </animated.div>

                <animated.div
                    className={styles.land}
                    style={{
                        width: layerWidth,
                        height: layerHeight,
                        opacity: land4Opacity,
                    }}
                >
                    <div className={styles.elementWrapper}>
                        <animated.div className={styles.element}>
                            <img
                                src="/images/dear.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </animated.div>
                        <animated.div className={styles.element}>
                            <img
                                src="/images/birds.png"
                                alt="element"
                                width={layerWidth}
                                height={layerHeight}
                            />
                        </animated.div>
                    </div>
                </animated.div>

                {selectable && (
                    <>
                        <div
                            className={styles.arrowRight}
                            onClick={() => {
                                if (activeLand === 3) {
                                    onSelectLand(1);
                                } else {
                                    onSelectLand((activeLand + 1) as 2 | 3);
                                }
                            }}
                        >
                            <img width={129} height={77} src="/images/arrow.svg" alt="arrow" />
                        </div>
                        <div
                            className={styles.arrowLeft}
                            onClick={() => {
                                if (activeLand === 1) {
                                    onSelectLand(3);
                                } else {
                                    onSelectLand((activeLand - 1) as 1 | 2);
                                }
                            }}
                        >
                            <img width={129} height={77} src="/images/arrow.svg" alt="arrow" />
                        </div>
                    </>
                )}
            </animated.div>
        </div>
    );
};

export default Land;
