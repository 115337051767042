export const LINE_OFFICIAL_URL = 'https://lin.ee/Y2DbsHW';
export const SHOP_URL = 'https://www.launcher.com.tw/pages/peninsoundla-aboutlove';
export const IG_URL = 'https://www.instagram.com/peninsoundla/';
export const EMAIL_URL = 'info@launcher.com.tw';

export const SHOP_PENINSOUNDLA = 'https://www.launcher.com.tw/pages/peninsoundla';
export const SHOP_ABOUTLOVE_ALL = 'https://www.launcher.com.tw/pages/peninsoundla-aboutlove';
export const SHOP_ABOUTLOVE_DIGITAL = 'https://www.launcher.com.tw/products/aboutlove-digital';
export const SHOP_ABOUTLOVE_TOUR = 'https://www.launcher.com.tw/products/aboutlove-tour';
export const SHOP_ABOUTDESTINY = 'https://www.launcher.com.tw/products/aboutdestiny';
export const SHOP_ABOUTRESONACE = '';

export const UNLOCK_ABOUTDESTINY = 'https://line.me/R/ti/p/@peninsoundla';
