import classnames from 'classnames';
import { SHOP_PENINSOUNDLA } from '@/utils/links';

import Button from '@/components/atoms/Button';
import Title from '@/components/atoms/Title';

import styles from './index.module.css';

export interface SectionPurchaseProp {
    className?: string;
    onClickMore?: () => void;
}

const SectionPurchase: React.FC<SectionPurchaseProp> = ({ className, onClickMore = () => {} }) => {
    return (
        <div className={classnames(styles.purchaseProcress, className)}>
            <Title>購買流程</Title>
            <div className={styles.itemWrapper}>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <img src="/images/purchase-1.png" alt="intro" />
                    </div>
                    <div className={styles.label}>選擇體驗購買</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <img src="/images/purchase-2.png" alt="intro" />
                    </div>
                    <div className={styles.label}>信箱收到序號 / 行前通知</div>
                </div>
                <div className={styles.item}>
                    <div
                        className={styles.icon}
                        style={{
                            marginTop: '-5%',
                            marginBottom: '5%',
                        }}
                    >
                        <img src="/images/purchase-3.png" alt="intro" />
                    </div>
                    <div className={styles.label}>在 LINE 上輸入序號</div>
                    <div className={styles.note}>* 導覽版本不需輸入序號</div>
                </div>
                <div className={styles.item}>
                    <div className={styles.icon}>
                        <img src="/images/purchase-4.png" alt="intro" />
                    </div>
                    <div className={styles.label}>開始體驗</div>
                </div>
            </div>
            <div className={styles.label}>注意事項</div>
            <div className={styles.caution}>
                <ol>
                    <li>
                        共聲島體驗採「線上訂購」，訂購完成後，請記得到 Email
                        收信，注意信件是否跑到垃圾信件匣裡。
                    </li>
                    <li>
                        共聲島的體驗方式結合數位及實地踏訪，除了導覽版本有特定場次外，其餘購買方案皆不限時間和次數。
                    </li>
                    <li>
                        體驗共聲島的人需自備耳機以及有行動網路與 LINE 的手機，並且確認能夠正常使用
                        GPS 定位以及相機功能，以獲得最徍遊戲體驗！
                    </li>
                    <li>訂購若有疑問，可透過客服信箱與我們聯繫（info@launcher.com.tw）</li>
                </ol>
            </div>
            <div className={styles.more} onClick={onClickMore}>
                更多注意事項
            </div>
            <a href={SHOP_PENINSOUNDLA} target="_blank" rel="noopener noreferrer">
                <Button>馬上購買</Button>
            </a>
        </div>
    );
};

export default SectionPurchase;
