export interface FAQRawItem {
    id: number;
    question: string;
    type: 'about' | 'howTo' | 'purchase' | 'device';
    answer: string;
    htmlanswer: string;
}

export const faqList: FAQRawItem[] = [
    {
        id: 1,
        type: 'about',
        question: '什麼是共聲島？',
        answer: '<p><strong>「共聲島」為恆春半島首個實境數位聲音體驗</strong>，突破傳統導覽限制，大家只需要耳機和手機，即可按照自己的步調，隨時探索古城；同時能透過互動系統與NPC對談、解謎闖關、集圖鑑，與店家互動，品味當地美食，結識居民，感受南國人情味，享受全新的旅程選擇。</p>',
        htmlanswer:
            "<span class='highlight'>「共聲島」為恆春半島首個實境數位聲音體驗</span>，突破傳統導覽限制，讓旅人們按照自己的步調自由探索古城。只需手機和耳機，即可隨時體驗，享受全新的旅程選擇。\n漫步其中，旅人將沈浸於豐富多元的聲音資料庫，從自然環境到當地文化，以全新方式呈現恆春的風情。\n除了聆聽，旅人還能透過互動系統與NPC對談、解謎闖關、集圖鑑；並與當地店家互動，品味當地美食，結識居民，感受南國人情味。",
    },
    {
        id: 2,
        type: 'about',
        question: '共聲島在哪裡玩？',
        answer: '<p>目前推出的<strong>主要範圍為恆春鎮上各城門周邊及老街區域</strong>，未來會再開發新的區域範圍，歡迎追蹤<a href="IG_URL">共聲島IG帳號</a>獲得最新消息。</p>',
        htmlanswer:
            '「共聲島」為恆春半島實境數位聲音體驗，目前推出之路線<span class=\'highlight\'>主要設點於恆春鎮上各城門周邊及老街區域</span>，欲解鎖「共聲島」完整行程與內容，旅人需親自前來「恆春半島」並沈浸其中。\n共聲島官網及IG帳號將持續釋出島上最新消息，歡迎追蹤。\n小提示：報名可在線上完成哦！<a href="APPLY_URL">點此報名</a>',
    },
    {
        id: 3,
        type: 'about',
        question: '共聲島怎麼玩？',
        answer: '<p>共聲島主要玩法為「步行＋聆聽」，報名後，準備好手機及耳機即可出發。\n小提示：由於恆春夏季天氣炎熱，聆聽過程中，請別忘了多多補充水分、慎防中暑。</p>',
        htmlanswer:
            '「共聲島」主要玩法為「步行＋聆聽」，報名後，僅需自備手機及耳機即可隨時出發。\n小提示：由於恆春夏季天氣炎熱，聆聽過程中，請旅人也別忘了多多補充水分、慎防中暑。',
    },
    {
        id: 4,
        type: 'about',
        question: '共聲島體驗多少錢？',
        answer: `<p>共聲島目前的體驗費用為：
                    <ul>
                        <li>戀の時光町（導覽版）：599 元/人</li>
                        <li>戀の時光町（數位版）：350 元/人</li>
                        <li>限時轉運站：免費</li>
                        <li>共聲電台：350 元/人</li>
                    </ul></p>`,
        htmlanswer:
            "「共聲島」現有及未來將推出之路線於不同期間將有不同定價，目前「戀の時光町（導覽版）」路線正以<span class='highlight'>搶先登島早鳥價199元</span>販售中。",
    },
    {
        id: 5,
        type: 'about',
        question: '如何登入共聲島？',
        answer: '<p><a href="SHOP_PENINSOUNDLA">點此網址</a> 立即登入共聲島，別錯過早鳥價！\n獲取更多資訊及福利請加入<a href="LINE_URL">LINE官方帳號</a>。</p>',
        htmlanswer:
            '登上「共聲島」的方式非常簡單，短短幾分鐘即可完成！\n搶先登島早鳥價別錯過，立即<a href="APPLY_URL">點此報名</a>\n獲取更多資訊及福利請加入<a href=\'IG_URL\'>LINE官方帳號</a>',
    },
    {
        id: 6,
        type: 'howTo',
        question: '體驗時間多長？',
        answer: `<p>共聲島目前共有四種不同體驗：
                <ul>
                    <li>戀の時光町（導覽版）將由導覽員統一帶領，體體驗時間約為 1.5 小時，<strong>體驗完成後可獲得數位版</strong>。</li>
                    <li>戀の時光町（數位版）的體驗時間約為 1 小時。體驗過程中旅人能依照自己的步調，暫停與停留於周邊店家，不受時間約束。詳細比較可參考<em>Q13</em>導覽版與數位版差異。</li>
                    <li>限時轉運站的體驗內容包含與店家互動解謎，體驗時間可長可短，可依個人時間、興趣調整。</li>
                    <li>共聲電台的體驗時間約為 1 小時。體驗過程中旅人能依照自己的步調，停留於特定場域，不受時間約束。</li>
                </ul></p>`,
        htmlanswer:
            '體驗「共聲島」不受時間約束，旅人們可以依自己的步調和興趣，自由地探索恆春古城的每一個角落，並可反覆聆聽、感受與思考。現有路線體驗全長參考時間大約為1-2小時。',
    },
    {
        id: 7,
        type: 'howTo',
        question: '有推薦的體驗時段嗎？',
        answer: '<p>共聲島為戶外實境遊玩方式，由於恆春夏季天氣炎熱，10:00之前或14:00之後啟程為最佳時段。另外，部分聆聽點無照明設備，夜間步行較危險且觀看體驗不佳，建議太陽下山之前完成體驗。聆聽過程中，請別忘了多多補充水分、慎防中暑。</p>',
        htmlanswer:
            '「共聲島」為戶外實境遊玩方式，由於恆春夏季天氣炎熱，推薦於10:00之前或14:00之後啟程。另外，由於部分聆聽點位無照明設備，夜間步行較危險且觀看體驗不佳，建議於太陽下山之前完成體驗。聆聽過程中，請旅人也別忘了多多補充水分、慎防中暑。',
    },
    {
        id: 8,
        type: 'howTo',
        question: '是否有年齡限制？適不適合小孩玩？',
        answer: '<p>共聲島需於恆春鎮上步行移動，且需自備手機及耳機，12歲以下兒童建議由家長或師長帶領並全程陪同遊玩。</p>',
        htmlanswer:
            '「共聲島」無遊玩年齡限制，「戀の時光町」以說故事的方式講述文化與歷史，適合學齡及以上之兒童及青少年聆聽、體驗、學習。體驗「共聲島」需自備手機及耳機，且需於恆春鎮上步行移動，12歲以下兒童建議由家長或師長帶領並全程陪同遊玩。',
    },
    {
        id: 9,
        type: 'howTo',
        question: '操作難度高不高？不太會用3C的年長者適不適合玩？',
        answer: '<p>共聲島的體驗模式皆以LINE介面為主要系統，只要會用LINE就能玩共聲島，歡迎加入<a href="LINE_URL">LINE官方帳號</a>來試看看吧！\n若你對於使用數位系統之能力較無自信，建議報名「戀の時光町（導覽版）」，由熱情細心的導覽員帶領大家體驗。請參考<em>Q13</em>導覽版與數位版差異。</p>',
        htmlanswer:
            '「共聲島」之手機互動系統操作難易度適中，適合能夠自在使用手機各app及網站之使用者體驗。\n若您對於使用數位系統之能力較無自信，請不用擔心，建議報名「戀の時光町（導覽版）」，由熱情細心的真人導覽員帶您一步步體驗。',
    },
    {
        id: 10,
        type: 'howTo',
        question:
            '非中文母語者可以體驗嗎？\n"I am a foreign tourist, if this game is suitable for me to participate in?"',
        answer: '<p>共聲島現有區域「戀の時光町」全內容皆為華語，不適合不擅長華語之遊客體驗。未來共聲島將推出無語言門檻之區域，敬請關注共聲島 <a href="IG_URL">IG</a> 及官網。</p>\n<p>The current listening content of the route "About Love" in PeninSOUNDla is predominantly in Mandarin, which may not be suitable for visitors who are not proficient in Mandarin. In the future, more routes with no language barriers will be available for selection. Please stay tuned to PeninSOUNDla\'s <a href="IG_URL">Instagram</a> and official website for updates.</p>',
        htmlanswer:
            "共聲島現有路線「戀の時光町」全內容皆為華語，較不適合不擅長華語之遊客體驗。未來「共聲島」將推出無語言門檻之路線，敬請關注共聲島IG及官網。\n\nThe current listening content of the route About Love in PeninSOUNDla is predominantly in Mandarin, which may not be suitable for visitors who are not proficient in Mandarin. In the future, more routes with no language barriers will be available for selection. Please stay tuned to PeninSOUNDla's Instagram and official website for updates.",
    },
    {
        id: 11,
        type: 'howTo',
        question: '體驗共聲島可以獲得什麼？',
        answer: '<p>體驗共聲島可聽到恆春半島的故事、歷史和隱藏在場景裡的神秘聲響，沈浸於豐富多彩的「聲音」資料庫中。此外，透過手機的互動系統，可與NPC對談、解謎、闖關、收集圖鑑；同時能與在地店家互動，邊吃、邊喝、邊閒聊、邊購物，交流彼此的故事，感受專屬於南國的人情味，最後帶著富含聲音、文化、故事、美食的獨特回憶滿載而歸。</p>',
        htmlanswer:
            '在體驗「共聲島」的過程中，您將會聆聽恆春半島的故事、歷史和隱藏在場景裡的神秘聲響，沈浸於豐富多彩的「聲音」資料庫中。\n此外，透過手機上的互動系統，您將與NPC對談，在古城中使用手機解謎、闖關、收集圖鑑，收穫滿滿的成就感；透過與當地店家互動，在邊吃、邊喝、邊閒聊、邊購物的過程中，結識當地的居民、交流彼此的故事，感受專屬於南國的人情味，最後帶著富含聲音、文化、故事、美食的獨特回憶滿載而歸。',
    },
    {
        id: 12,
        type: 'howTo',
        question: '能不能同天參加兩個體驗？',
        answer: '<p>共聲島於2024年10月後將推出其他內容豐富且風格多元的區域，非常歡迎於同一天內參與兩個以上體驗。</p>',
        htmlanswer:
            '2024年10月後「共聲島」將推出多條內容豐富且風格多元的路線，非常歡迎您於同一天內體驗兩條以上路線。',
    },
    {
        id: 13,
        type: 'howTo',
        question: '「戀の時光町」導覽版和數位版差在哪？',
        answer: '<p>「戀の時光町」分成<strong>導覽版</strong>及<strong>數位版</strong>兩個版本。</p>\n<p>2024 年 6 月搶先發行的<strong>導覽版，由真人導覽員全程陪同與解說</strong>，參加者填寫問卷後即可獲得無導覽員陪同的數位版，將5位女子的故事收藏在LINE系統，大家再次體驗數位版的時間和行程可自由決定和規劃。</p>\n<p>未來將開放單獨販售數位版。</p>',
        htmlanswer:
            "「戀の時光町」路線分成<span class='highlight'>導覽版</span class='highlight'>及<span>數位版</span>兩個版本。\n\n導覽版為 2024 年 6 月搶先發行版，含真人導覽員全程陪同與解說，除可聆聽、體驗數位版內容之外，還有由墾丁國家公園林瓊瑤老師為「戀の時光町」設計之專屬導覽內容。\n數位版目前尚未開放購買，但參與過導覽版的旅人將能免費體驗。旅人可自由決定體驗時間。有更自主的行程規劃與時間控制。\n\n旅人可依照個人喜好不同，報名參加不同版本的「戀の時光町」。",
    },
    {
        id: 14,
        type: 'howTo',
        question: '有特殊的衣著、裝備嗎？',
        answer: '<p>體驗共聲島無服裝限制，每個人可視天氣及自身情況，自由穿著舒適之服裝。</p>',
        htmlanswer: '體驗「共聲島」無服裝限制，旅人可視天氣及自身情況，自由穿著舒適之服裝。',
    },
    {
        id: 15,
        type: 'howTo',
        question: '可以多人一起體驗嗎？',
        answer: '<p>共聲島以數位體驗型態為主，只要準備好各自的耳機和手機，加入共聲島官方LINE帳號，即可獨自體驗、或三五好友相約體驗。\n唯「戀の時光町（導覽版）」是由真人導覽員全程陪同和解說，為確保旅人體驗感受良好，將規劃10-15人為人數限制。</p>',
        htmlanswer:
            '共聲島以數位體驗型態為主，因此現有路線皆無人數限制，可單人體驗、也適合多人同遊。\n唯「戀の時光町（導覽版）」含真人導覽員全程解說，為確保旅人體驗感受良好，此路線將有特殊人數限制（10-15人為佳）。',
    },
    {
        id: 16,
        type: 'howTo',
        question: '附近有停車場嗎',
        answer: '<p>恆春鎮設有立體停車場及公有停車場，歡迎使用。</p>\n<ul>\n<li><a href="https://maps.app.goo.gl/9TEvHai9gtD67aoe6">立體停車場位置</a></li>\n<li><a href="https://maps.app.goo.gl/7oi9Jg9BzCDrAnHQA">公有停車場位置</a></li>\n</ul>',
        htmlanswer:
            '「共聲島」主要設點於恆春鎮上各城門周邊及老街區域，停車便利（非節假日），無專用停車場。',
    },
    {
        id: 17,
        type: 'howTo',
        question: '附近有地方可以置物嗎？',
        answer: '<p>共聲島無專用置物區，建議旅人穿著輕便服裝參加體驗。</p>',
        htmlanswer:
            '「共聲島」無專用置物區與盛水設施，建議旅人輕便體驗。恆春鎮老街店家選項豐富多元，報名參加「共聲島」即可獲得老街店家之優惠券，旅人可至老街享用各式飲品。',
    },
    {
        id: 18,
        type: 'howTo',
        question: '如果在體驗過程中迷路了該怎麼辦？',
        answer: '<p>共聲島現有體驗皆不複雜，點位也很容易尋找，請不用擔心迷路問題。若在體驗過程中，仍對點位感到疑惑，可於LINE系統查看教學區塊。</p>',
        htmlanswer:
            '「共聲島」現有路線皆不複雜，點位也很容易尋找，請不用擔心迷路問題。若您在體驗過程中，仍對點位感到疑惑，請返回LINE系統主頁再次查看教學區塊。',
    },
    {
        id: 19,
        type: 'purchase',
        question: '購買流程',
        answer: '<p>共聲島體驗之購買流程如下：</p>\n<ol>\n<li>購買：選擇欲體驗的區域，付款完成訂單。</li>\n<li>收取信件：完成訂單後，我們將發送解鎖序號至你的信箱，請注意是否有跑到垃圾信件匣。</li>\n<li>解鎖體驗：點擊信件中的序號連結，或是點選LINE的「開始體驗」按鈕，輸入序號解鎖體驗。</li>\n<li>開始體驗：挑一個風和日麗的日子，帶著充飽電的手機和耳機，開始體驗吧！</li>\n</ol>\n<p>唯「戀の時光町（導覽版）」是固定場次體驗，其購買流程如下：</p>\n<ol>\n<li>購買：透過購買連結選擇您可以參與體驗的時段，並於 48 小時內付款完成訂單。</li>\n<li>收取信件：完成訂單後，我們將發送相關事項（包含集合地點、成團資訊等）至你的信箱。請注意收信，並完成指示。</li>\n<li>當天報到：活動當天帶著充飽電的手機至報到地點集合！</li>\n</ol>',
        htmlanswer:
            '共聲島體驗之購買流程如下：\n<ol><li>購買：選擇您欲體驗的區域，付款完成訂單。</li><li>收取信件：完成訂單後，我們將發送解鎖序號至您的信箱，請注意收信。</li><li>解鎖體驗：點擊信件中的序號連結，或是點選 Line 的「開始體驗」按鈕，輸入序號解鎖體驗。</li><li>開始體驗：挑一個風和日麗的日子，帶著充飽電的手機和耳機，開始體驗吧！</li></ol>\n\n唯「戀の時光町（導覽版）」是固定場次體驗，其購買流程如下：\n<ol><li>購買：選擇您可以參與體驗的時段，付款完成訂單。</li><li>收取信件：完成訂單後，我們將發送相關事項（包含集合地點、成團資訊等）至您的信箱。請注意收信，並完成指示。</li><li>當天報到：活動當天帶著充飽電的手機至報到地點集合！</li></ol>',
    },
    {
        id: 20,
        type: 'purchase',
        question: '多人一起報名有沒有優惠？',
        answer: '<p>目前共聲島推出的「戀の時光町（導覽版）」正以早鳥價199元/人販售中，非常歡迎大家揪團報名參加。若需要有團體報名的專屬服務，詳情請洽客服信箱 info@launcher.com.tw。</p>',
        htmlanswer:
            '目前共聲島第一條路線「戀の時光町（導覽版）」正以早鳥價199元販售中，非常歡迎您以團體報名參加。團體遊客將享有專屬福利，詳情請洽客服信箱info@launcher.com.tw 或撥打客服專線 0936347382，我們將儘速為您解答與安排。',
    },
    {
        id: 21,
        type: 'purchase',
        question: '長輩、小孩、學生、縣民有沒有優惠價？',
        answer: '<p>目前共聲島推出的「戀の時光町（導覽版）」正以早鳥價199元販售中，尚未有其他的優惠折扣。未來共聲島將持續推出不同優惠組合，敬請關注<a href="IG_URL">共聲島IG</a>及加入<a href="LINE_URL">LINE官方帳號</a>獲取最新消息。</p>',
        htmlanswer:
            "目前共聲島第一條路線「戀の時光町（導覽版）」正以早鳥價199元販售中，尚未有特殊身分折扣，而團體報名請見Q20。未來共聲島將持續推出不同優惠組合，敬請關注<a href='https:",
    },
    {
        id: 22,
        type: 'purchase',
        question: '發票可以打統編嗎？',
        answer: '<p>若有抬頭和統編的發票需求，購買時請記得填妥相關資訊，購買完成後電子發票將會自動寄送到您的信箱。</p>',
        htmlanswer:
            '若您有發票需求，請聯絡客服信箱 info@launcher.com.tw 或撥打客服專線 0936347382，並提供以下資訊：\n<ul><li>訂單編號</li><li>公司抬頭</li><li>公司統編</li></ul>\n我們將儘速與您聯繫',
    },
    {
        id: 23,
        type: 'purchase',
        question: '可以現場報名嗎？',
        answer: `共聲島目前體驗除了「限時轉運站」皆需事先購買。
                 購買注意事項：
                 <ol>
                    <li>「戀の時光町（導覽版）」購買並完成付款的時間需在體驗日當日前之上午10:00前。</li>
                    <li>其他數位體驗路線可隨時購買，購買後將在 24 小時內寄送體驗序號。若急需獲取序號，請聯繫 info@launcher.com.tw。</li>
                    <li>自網站下訂後若超過付款期限，網站將自動取消訂單。付款期限將依不同付款方式有所不同，請參照購買指示付款。</li>
                </ol>`,
        htmlanswer:
            '共聲島目前尚未開放現場報名，請旅人事先購買體驗。\n戀の時光町（導覽版）最晚報名時間如下：\n<ul><li>09:00 場次：前一天 17:00 關閉報名</li><li>16:00 場次：當天 12:00 關閉報名</li></ul>',
    },
    {
        id: 24,
        type: 'purchase',
        question: '退費說明',
        answer: '<p>共聲島以數位體驗為主，退費方法如下：</p>\n<ul>\n<li>訂單完成的7日內，在尚未啟動序號前，皆可全額退款。</li>\n<li>訂單完成 7日後或已啟用序號，將全額不予退款。但你仍可在未啟用序號前轉讓體驗給他人，請參考<em>Q25</em>轉讓說明。</li>\n</ul>\n<p>唯「戀の時光町（導覽版）」有固定體驗場次，其退費原則為：</p>\n<ul>\n<li>於導覽活動發生日前第31日前取消，全額退費；</li>\n<li>於導覽活動發生日前第11日至第30日前取消，需收取費用全額10%手續費；</li>\n<li>於導覽活動發生日前第4日至第10日前取消，需收取旅遊費用全額30%手續費；</li>\n<li>於導覽活動發生日前第1日至第3日前取消，需收取旅遊費用全額50%手續費；</li>\n<li>於導覽活動發生日當日取消、集合逾時、因個人因素私自脫隊、及未取消訂單或聯繫客服者恕不退費。</li>\n</ul>\n<p>有退費需求可於購買網站取消訂單，或聯絡客服信箱 info@launcher.com.tw，並提供訂單編號，我們將儘速與你聯繫。</p>',
        htmlanswer:
            '共聲島以數位體驗為主，退費方法如下：\n<ul><li>訂單完成的7日內，在尚未啟動序號前，皆可全額退款*。</li><li>訂單完成 7日後或已啟用序號，將全額不予退款。但您仍可在未啟用序號前轉讓體驗給他人，請參考Q25轉讓說明。</li></ul>\n\n唯「戀の時光町（導覽版）」有固定體驗場次，其退費原則為：\n<ul><li>活動前2天前，申請取消訂單，可全額退款*。</li><li>活動前1天，申請取消訂單，可退款50%*。</li><li>於活動當日及當日後申請取消，或活動當日未出席者，全額不予退款。</li></ul>\n\n有退費需求請聯絡客服信箱 info@launcher.com.tw 或撥打客服專線 0936347382，並提供訂單編號，我們將儘速與您聯繫。\n\n*退款將酌收部分手續費\n',
    },
    {
        id: 25,
        type: 'purchase',
        question: '轉讓說明',
        answer: '<p>若你欲轉讓「戀的時光町（導覽版）」體驗，請<strong>最晚於活動前一日中午 12:00 前</strong>，聯絡客服信箱 info@launcher.com.tw，並提供訂單編號與當天參與者姓名、電話、信箱，我們將儘速與你聯繫。</p>\n<p>若你購買其他體驗，且尚未使用序號，你可以自由將序號或連結分享給他人。小提醒：序號只能使用一次喔！</p>',
        htmlanswer:
            "若您欲轉讓「戀的時光町（導覽版）」體驗，請<span class='highlight'>最晚於活動前一日 17:00 前</span>，聯絡客服信箱 info@launcher.com.tw 或撥打客服專線 0936347382。\n\n若您購買其他體驗，且尚未使用序號，您可以自由將序號或連結分享給他人。小提醒：序號只能使用一次喔！",
    },
    {
        id: 26,
        type: 'purchase',
        question: '可以包團嗎',
        answer: '<p>若你有「戀的時光町（導覽版）」的包團需求，請聯絡客服信箱 info@launcher.com.tw。</p>',
        htmlanswer:
            '若您有「戀的時光町（導覽版）」的客製包團需求，請聯絡客服信箱info@launcher.com.tw 或撥打客服專線 0936347382。\n\n其餘體驗您皆可自由規劃體驗時間。只要大家都買好體驗，約好時間，就可以自行出團囉！',
    },
    {
        id: 27,
        type: 'purchase',
        question: '報名後卻不能出團的規則為何',
        answer: '<p>「戀的時光町（導覽版）」因有真人導覽行程，將參照以下規則出團：</p>\n<ol>\n<li>活動7天前，若因未達最低成團人數將停止開放報名該場次。我們將以「Email」或「手機簡訊」通知已報名者，並將已繳費用全額退還。</li>\n<li>活動當日若因氣候不佳或不可抗力之因素、發布停班停課通知、中央氣象局發布「豪雨」或「豪大雨」特報*，我們將會以「Email」或「手機簡訊」通知該次活動取消，並將已繳費用將全額退還。</li>\n</ol>\n<p>若非上述理由而自行取消者，退費標準請參照<em>Q24</em>。</p>\n<p>*參考<a href="https://www.cwb.gov.tw/V8/C/">天氣即時監測</a></p>',
        htmlanswer:
            "「戀的時光町（導覽版）」因有真人導覽行程，將參照以下規則出團：\n<ol><li>活動7天前，若因未達最成團人數將停止開放報名該場次。火箭人將以「Email」或「手機簡訊」通知已報名者，並將已繳費用全額退還。</li><li>活動當日若因氣候不佳，發布停班停課通知、「豪雨」或「大豪雨」特報*，火箭人將會以「Email」或「手機簡訊」通知您本次活動取消，已繳費用將全額退還</li></ol>\n\n其餘體驗可自由規劃體驗時間，您可以一人出團，也可以三五好友相揪出團，沒有出團規則喔！\n\n*參考<a href='https://www.cwb.gov.tw/V8/C/'>天氣即時監測</a>",
    },
    {
        id: 28,
        type: 'device',
        question: '我需要準備什麼設備？',
        answer: '<p>體驗共聲島只需自備一人一台有行動網路的手機及耳機即可隨時出發。\n若參與的體驗為「戀の時光町（導覽版）」不需自備耳機，導覽現場將提供全罩式無線耳機，無線耳機每場次使用完畢將進行擦拭消毒，現場亦備有衛生耳機套供索取。\n小提示：為了完整體驗，請事先充飽手機電量、確認手機 GPS 定位系統、相機可以正常運作。</p>',
        htmlanswer:
            '報名「共聲島」後，僅需自備手機及耳機即可隨時出發。\n小提示：為了完整體驗，請事先充飽手機電量、確認手機 GPS 定位系統、相機可以正常運作。',
    },
    {
        id: 29,
        type: 'device',
        question: '我沒有帶耳機怎麼辦',
        answer: '<p>建議自備<strong>耳機才能享受最佳聆聽體驗</strong>哦！若未攜帶耳機，可開啟擴音播放但會受到環境干擾影響，而無法擁有絕佳的聲音體驗，因此亦可至附近商店購買耳機。\n若參與的體驗為「戀の時光町（導覽版）」，導覽現場將提供全罩式無線耳機，無線耳機每場次使用完畢將進行擦拭消毒，現場亦備有衛生耳機套供索取。</p>',
        htmlanswer:
            '共聲島以數位體驗為主，請務必自備耳機前來哦！若旅行途中未攜帶耳機，可至附近商店購買。\n若您參與的體驗為「戀の時光町（導覽版）」，導覽現場將提供全罩式無線耳機，無線耳機每場次使用完畢將進行擦拭消毒，現場亦備有衛生耳機套供索取。',
    },
    {
        id: 30,
        type: 'device',
        question: '手機 GPS 定位壞掉怎麼辦？',
        answer: '<p>共聲島體驗結合數位及實地走訪，若自備的手機 GPS 定位壞掉，將無法體驗部分行程，請於體驗前確認。\n若你仍想參與，可報名「戀の時光町（導覽版）」，由熱情細心的真人導覽員帶領體驗。</p>',
        htmlanswer:
            '共聲島體驗結合數位及實地走訪，若您的手機 GPS 定位壞掉，將無法體驗部分行程，請在購買前確認。\n若您仍想參與，可報名「戀の時光町（導覽版）」，由熱情細心的真人導覽員帶您體驗。',
    },
    {
        id: 31,
        type: 'device',
        question: '手機的相機壞掉怎麼辦？',
        answer: '<p>共聲島體驗中，部分互動會使用到相機，若自備手機的拍照功能壞掉，可跳過該互動步驟繼續進行體驗。</p>',
        htmlanswer: '共聲島體驗中，部分互動會使用到相機，若您的相機壞掉，可跳過互動繼續進行體驗。',
    },
    {
        id: 32,
        type: 'device',
        question: '有行動電源租借服務嗎？',
        answer: '<p>若臨時需要行動電源，可前往 ChargeSpot 站點租借喔！因此提醒：進行共聲島體驗前，請<strong>記得先充飽手機</strong>！</p>',
        htmlanswer:
            "進行共聲島體驗前，請<span class='highlight'>記得先充飽手機</span>！\n若臨時需要行動電源，可前往 ChargeSpot 站點租借喔！",
    },
];
