import classnames from 'classnames';

import PageLayout from '@/components/organisms/PageLayout';
import Title from '@/components/atoms/Title';
import Button from '@/components/atoms/Button';
import Tabs from '@/components/molecules/Tabs';

import styles from './index.module.css';
import { SHOP_ABOUTLOVE_DIGITAL, SHOP_ABOUTLOVE_TOUR } from '@/utils/links';

export interface LayoutAboutLoveProp {
    className?: string;
    active?: boolean;
    activeTab?: 'tour' | 'digital';
    onClickTab?: (tab: string) => void;
}

const LayoutAboutLove: React.FC<LayoutAboutLoveProp> = ({
    className,
    active,
    activeTab = 'tour',
    onClickTab = () => {},
}) => (
    <PageLayout active={active}>
        <div className={classnames(styles.layoutAboutLove, className)}>
            <Title className={styles.title}>戀の時光町</Title>

            <Tabs
                className={styles.tabs}
                activeTab={activeTab}
                tabList={[
                    {
                        key: 'tour',
                        label: '導覽版',
                    },
                    {
                        key: 'digital',
                        label: '數位版',
                    },
                ]}
                onClickTab={onClickTab}
            />

            {activeTab === 'tour' && (
                <>
                    <section className={styles.intro}>
                        「戀の時光町」是由無數愛情故事組成的歷史街區，參加此體驗的旅人可跟隨導覽員的步伐、打開穿越時空的入口。導覽員一邊解説、一邊引導旅人靠近埋藏歲月秘密的建築，聆聽五則關於「愛」的故事。
                        <br />
                        <br />
                        「戀の時光町」體驗全程有五個重要的地點，透過耳機能聽見五位時空背景各異的女性，在歷史長河的流動中，因著不同的緣份由來到共聲島。他們娓娓道來那段甜蜜或心碎的往事，並留下了未竟的心願，唯有透過手機互動系統幫她們實現。
                    </section>

                    <div className={styles.itemWrapper}>
                        <div className={styles.item}>
                            <div className={styles.name}>體驗時間</div>
                            <div className={styles.value}>約 90 分鐘</div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.name}>集合地點</div>
                            <div className={styles.value}>
                                <span>海角七號阿嘉的家</span>
                                <br />
                                <span style={{ marginLeft: -8 }}>（恆春鎮光明路90號）</span>
                            </div>
                        </div>
                        <div className={styles.item}>
                            <div className={styles.name}>名額</div>
                            <div className={styles.value}>10 人成團，上限 15 人</div>
                        </div>
                    </div>

                    <a
                        className={styles.btn}
                        href={SHOP_ABOUTLOVE_TOUR}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button>解鎖路線</Button>
                    </a>
                </>
            )}

            {activeTab === 'digital' && (
                <>
                    <section className={styles.intro}>
                        旅人戴上耳機，分別靠近五棟不同時空背景的建築物前，即可進入一個充滿歷史和浪漫的旅程。透過精細的錄音工程，可以聽見五位女子述說他們的愛情故事。每段感情背後，都承載著深厚的歷史背景，從二戰時期的情緣、到戰後的戀愛，以及當代的浪漫經歷。
                        <br />
                        <br />
                        「戀の時光町-數位版」的靈活性和便捷性，讓旅人能夠根據自己的節奏和時間來探索每個故事，可以隨時暫停、隨時回顧，亦或是打開搜尋功能深入了解文史細節，同時享有收集圖鑑的樂趣。
                    </section>

                    <div className={styles.itemWrapper}>
                        <div className={classnames(styles.digital, styles.item)}>
                            <div className={styles.name}>體驗時長</div>
                            <div className={classnames(styles.value, styles.digital)}>
                                約 60 分鐘
                            </div>
                        </div>

                        <div className={classnames(styles.digital, styles.item)}>
                            <div className={styles.name}>地點</div>
                            <div className={classnames(styles.value, styles.digital)}>恆春鎮</div>
                        </div>
                    </div>

                    <a
                        className={styles.btn}
                        href={SHOP_ABOUTLOVE_DIGITAL}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <Button>解鎖路線</Button>
                    </a>
                </>
            )}
        </div>
    </PageLayout>
);

export default LayoutAboutLove;
