import classnames from 'classnames';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useLockBodyScroll } from '@/utils/hooks';
import { useNavigate } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { createBreakpoint } from 'react-use';

import SkyBackground from '@/components/organisms/SkyBackground';
import RocketGroup, { RocketGroupProp } from '@/components/molecules/RocketGroup';
import Header, { HeaderProp } from '@/components/organisms/Header';
import Land from '@/components/organisms/Land';
import Intro from '@/components/organisms/Intro';
import Main from '@/components/organisms/Main';
import LayoutFaq from '@/components/organisms/LayoutFaq';
import LayoutAboutLove from '@/components/organisms/LayoutAboutLove';
import LayoutAboutDestiny from '@/components/organisms/LayoutAboutDestiny';
import LayoutAboutResonance from '@/components/organisms/LayoutAboutResonance';
import ShopFixedLink from '@/components/atoms/ShopFixedLink';
import MainStatic from '@/components/organisms/MainStatic';

import { SectionFaqProp } from '@/components/organisms/SectionFaq';

import styles from './index.module.css';

export interface MainProp {
    className?: string;
}

const useBreakpoint = createBreakpoint();

type LandStage = 'bottomUp' | 'floating' | 'enlarge' | 'select' | 'leaving';

const App: React.FC<MainProp> = ({ className }) => {
    const breakpoint = useBreakpoint();
    const [staticMode, setStaticMode] = useState(breakpoint === 'tablet');

    useEffect(() => {
        setStaticMode(breakpoint === 'tablet');
    }, [breakpoint]);

    const navigate = useNavigate();
    const { pathname, hash } = useLocation();
    const pathnameRef = useRef(pathname);

    const [menuActive, setMenuActive] = useState(false);

    useLockBodyScroll(pathname !== '/' || menuActive);

    const faqFilter = useMemo<SectionFaqProp['filter']>(() => {
        const filter = hash.replace('#', '');

        if (['about', 'howTo', 'purchase', 'device'].indexOf(filter) !== -1) {
            return filter as SectionFaqProp['filter'];
        }

        return 'about';
    }, [hash]);

    const aboutLoveTab = useMemo<'tour' | 'digital'>(() => {
        const tab = hash.replace('#', '');

        if (['tour', 'digital'].indexOf(tab) !== -1) {
            return tab as 'tour' | 'digital';
        }

        return 'tour';
    }, [hash]);

    const [countdown, setCountdown] = useState(false);

    const [headerType, setHeaderType] = useState<HeaderProp['stage']>(
        staticMode ? 'main' : 'title',
    );
    const [rocketStage, setRocketStage] = useState<RocketGroupProp['stage']>('loading');
    const [smallRocket, setSmallRocket] = useState(false);
    const [isRocketBottom, setRocketBottom] = useState(false);
    const [underneathTransiing, setUnderneathTransiting] = useState(false);

    const [starProgress, setStarProgress] = useState(0);
    const [lightProgress, setLightProgress] = useState(0);
    const [mainProgress, setMainProgress] = useState(0);
    const [groundProgress, setGroundProgress] = useState(0);
    const [underneathProgress, setUnderneathProgress] = useState(0);

    const [landBottomUpProgress, setLandBottomUpProgress] = useState(0);
    const [landFloatingProgress, setLandFloatingProgress] = useState(0);
    const [landEnlargeProgress, setLandEnlargeProgress] = useState(0);
    const [landSelectProgress, setLandSelectProgress] = useState(0);
    const [landLeavingProgress, setLandLeavingProgress] = useState(0);

    const [introActive, setIntroActive] = useState(false);
    const [headerActive, setHeaderActive] = useState(false);
    const [mainActive, setMainActive] = useState(false);
    const [activeLand, setActiveLand] = useState<1 | 2 | 3>(1);

    const resetSky = useCallback(() => {
        setStarProgress(0);
        setLightProgress(0);
        setMainProgress(0);
        setGroundProgress(0);
        setUnderneathProgress(0);
        setRocketStage('scrolling');
    }, []);

    const updateLandProgress = useCallback((stage: LandStage, progress: number) => {
        if (stage === 'bottomUp') {
            setLandBottomUpProgress(progress);
            setLandFloatingProgress(0);
            setLandEnlargeProgress(0);
            setLandSelectProgress(0);
            setLandLeavingProgress(0);
        } else if (stage === 'floating') {
            setLandBottomUpProgress(100);
            setLandFloatingProgress(progress);
            setLandEnlargeProgress(0);
            setLandSelectProgress(0);
            setLandLeavingProgress(0);
        } else if (stage === 'enlarge') {
            setLandBottomUpProgress(100);
            setLandFloatingProgress(100);
            setLandEnlargeProgress(progress);
            setLandSelectProgress(0);
            setLandLeavingProgress(0);
        } else if (stage === 'select') {
            setLandBottomUpProgress(100);
            setLandFloatingProgress(100);
            setLandEnlargeProgress(100);
            setLandSelectProgress(progress);
            setLandLeavingProgress(0);
        } else if (stage === 'leaving') {
            setLandBottomUpProgress(100);
            setLandFloatingProgress(100);
            setLandEnlargeProgress(100);
            setLandSelectProgress(100);
            setLandLeavingProgress(progress);
        }
    }, []);

    const resetLand = useCallback(() => {
        updateLandProgress('bottomUp', 0);
        setActiveLand(1);
    }, [updateLandProgress]);

    const onClickTitle = useCallback(() => {
        window.scrollTo(0, 0);

        resetSky();
        resetLand();
        setHeaderType(staticMode ? 'main' : 'title');
        setIntroActive(true);
        setHeaderActive(false);
        setMainActive(false);
        setMenuActive(false);
    }, [resetSky, resetLand, staticMode]);

    const onEnter = useCallback(() => {
        window.scrollTo(0, 0);
        setRocketStage('none');
        setIntroActive(false);

        setHeaderActive(true);
        setHeaderType(staticMode ? 'main' : 'title');
        updateLandProgress('floating', 100);

        if (staticMode) {
            window.setTimeout(() => {
                setHeaderType('main');
                setMainActive(true);
            }, 300);
        } else {
            window.setTimeout(() => {
                updateLandProgress('enlarge', 100);

                window.setTimeout(() => {
                    setMainActive(true);
                    setHeaderType('main');
                }, 800);
            }, 300);
        }
    }, [updateLandProgress, staticMode]);

    useEffect(() => {
        window.setTimeout(() => {
            setRocketStage('scrolling');

            if (pathnameRef.current === '/faq') {
                window.setTimeout(() => {
                    setRocketStage('none');
                    setIntroActive(false);
                    setHeaderActive(true);
                    setHeaderType('main');
                    setMainActive(true);

                    window.setTimeout(() => {
                        scroller.scrollTo(`section-faq`, { smooth: false });
                    }, 500);
                }, 500);
            } else if (pathnameRef.current === '/aboutlove') {
                window.setTimeout(() => {
                    setRocketStage('none');
                    setIntroActive(false);
                    setHeaderActive(true);
                    setHeaderType('main');
                    setMainActive(true);

                    window.setTimeout(() => {
                        scroller.scrollTo(`section-route`, { smooth: false });
                    }, 500);
                }, 500);
            } else {
                window.setTimeout(() => {
                    setIntroActive(true);
                }, 2000);
            }
        }, 4500);
    }, []);

    return (
        <div className={classnames(styles.app, className)}>
            {/* <Routes>
                <Route path="faq" element={<div />} />
            </Routes> */}

            <Header
                active={headerActive}
                stage={headerType}
                mobileMenuOpen={menuActive}
                onClickTitle={onClickTitle}
                onClickPage={(page) => {
                    scroller.scrollTo(`section-${page}`, { smooth: true });
                }}
                onToggleMobileMenu={(value) => setMenuActive(value)}
            />
            <SkyBackground
                starProgress={starProgress}
                lightProgress={lightProgress}
                mainProgress={mainProgress}
                groundProgress={groundProgress}
                underneathProgress={underneathProgress}
                underneathTransiing={underneathTransiing}
            />
            <RocketGroup stage={isRocketBottom ? 'none' : rocketStage} small={smallRocket} />
            {rocketStage !== 'loading' && (
                <div className={styles.mainContainer}>
                    {introActive && (
                        <Intro
                            staticMode={staticMode}
                            countdown={countdown}
                            onStarProgressUpdate={(progress) => {
                                setStarProgress(progress);

                                if (countdown) {
                                    setSmallRocket(progress > 55);
                                }
                            }}
                            onLightProgressUpdate={(value) => {
                                setLightProgress(value);

                                if (!countdown) {
                                    setRocketBottom(value > 0);
                                    updateLandProgress('bottomUp', value);
                                }
                            }}
                            onEnter={onEnter}
                        />
                    )}
                    {!staticMode && mainActive && (
                        <Main
                            activeRoute={activeLand}
                            onProgressUpdate={(progress) => {
                                setMainProgress(progress);
                            }}
                            onEnterRouteProgressUpdate={(progress) => {
                                setLandSelectProgress(progress);
                            }}
                            onRouteProgressUpdate={(progress) => {
                                if (progress > 0) {
                                    setLandSelectProgress(100);
                                }
                            }}
                            onLeavingProgressUpdate={(progress) => {
                                setLandLeavingProgress(progress);
                            }}
                            onGroundProgress={(progress) => {
                                setGroundProgress(progress);
                            }}
                            onUndergroundEnteringProgress={(progress) => {
                                setUnderneathProgress(progress);
                            }}
                        />
                    )}
                    {staticMode && mainActive && <MainStatic />}
                </div>
            )}
            {!staticMode && (
                <Land
                    display={rocketStage !== 'loading'}
                    bottomUpProgress={landBottomUpProgress}
                    floatingProgress={landFloatingProgress}
                    enlargeProgress={landEnlargeProgress}
                    selectProgress={landSelectProgress}
                    leavingProgress={landLeavingProgress}
                    activeLand={activeLand}
                    onSelectLand={(land) => setActiveLand(land)}
                />
            )}
            <ShopFixedLink active={mainActive} />

            <LayoutFaq
                active={rocketStage !== 'loading' && pathname === '/faq'}
                filter={faqFilter}
                onClickFilter={(f) => {
                    navigate(`/faq#${f}`);
                }}
            />
            <LayoutAboutLove
                active={rocketStage !== 'loading' && pathname === '/aboutlove'}
                activeTab={aboutLoveTab}
                onClickTab={(tab) => {
                    navigate(`/aboutlove#${tab}`);
                }}
            />
            <LayoutAboutDestiny
                active={rocketStage !== 'loading' && pathname === '/aboutdestiny'}
            />
            <LayoutAboutResonance
                active={rocketStage !== 'loading' && pathname === '/aboutresonance'}
            />
        </div>
    );
};

export default App;
