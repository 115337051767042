import classnames from 'classnames';

import styles from './index.module.css';

interface Tab {
    key: string;
    label: string;
}

export interface TabsProp {
    className?: string;
    activeTab?: Tab['key'];
    tabList: Tab[];
    onClickTab?: (key: Tab['key']) => void;
}

const Tabs: React.FC<TabsProp> = ({
    className,
    activeTab,
    tabList = [],
    onClickTab = () => {},
}) => (
    <div className={classnames(styles.tabs, className)}>
        {tabList.map(({ key, label }) => (
            <div
                key={key}
                className={classnames(styles.tab, activeTab === key && styles.active)}
                onClick={() => {
                    onClickTab(key);
                }}
            >
                {label}
            </div>
        ))}
    </div>
);

export default Tabs;
