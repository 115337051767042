import classnames from 'classnames';
import { animated, useSpring } from '@react-spring/web';

import Lock from '@/components/atoms/Lock';

import { route1, route2, route3 } from '@/utils/data';

import styles from './index.module.css';

export interface LandSliderProp {
    className?: string;
    activeLand?: number;
    onChange?: (landId: 1 | 2 | 3) => void;
}

const translateMap = {
    land1: {
        1: [0, 0, 1, 1, 0],
        2: [-25, 3, 0.6, 0.6, 0],
        3: [25, 3, 0.6, 0.6, 0],
    },
    land2: {
        1: [30, 5, 0.7, 0.7, 0],
        2: [0, -1.5, 1.05, 1.05, 0],
        3: [-28, 4, 0.65, 0.65, 0],
    },
    land3: {
        1: [-30, 5, 0.7, 0.7, 0],
        2: [25, 3.5, 0.65, 0.65, 0],
        3: [0, -1.5, 1.05, 1.05, 0],
    },
};

const LandSlider: React.FC<LandSliderProp> = ({
    className,
    activeLand = 1,
    onChange = () => {},
}) => {
    const land1Styles = useSpring({
        activeOpacity: activeLand === 1 ? 1 : 0,
        shadowOpacity: activeLand === 1 ? 0 : 0.3,
        translate: translateMap.land1[activeLand as 1 | 2 | 3],
    });

    const land2Styles = useSpring({
        activeOpacity: activeLand === 2 ? 1 : 0,
        shadowOpacity: activeLand === 2 ? 0 : 0.3,
        translate: translateMap.land2[activeLand as 1 | 2 | 3],
    });

    const land3Styles = useSpring({
        activeOpacity: activeLand === 3 ? 1 : 0,
        shadowOpacity: activeLand === 3 ? 0 : 0.3,
        translate: translateMap.land3[activeLand as 1 | 2 | 3],
    });

    return (
        <div className={classnames(styles.landSlider, className)}>
            <animated.div
                className={classnames(styles.land, styles.land1, styles.active)}
                style={{
                    opacity: land1Styles.activeOpacity,
                    transform: land1Styles.translate.to(
                        (x, y, scaleX, scaleY, rotate) =>
                            `translate(${x}%, ${y}%) scale(${scaleX}, ${scaleY}) rotate(${rotate}deg)`,
                    ),
                }}
            >
                <img src="/images/land-entire-01.png" alt="land-01-active" />
            </animated.div>
            <animated.div
                className={classnames(styles.land, styles.land1, styles.shadow)}
                style={{
                    opacity: land1Styles.shadowOpacity,
                    transform: land1Styles.translate.to(
                        (x, y, scaleX, scaleY, rotate) =>
                            `translate(${x}%, ${y}%) scale(${scaleX}, ${scaleY}) rotate(${rotate}deg)`,
                    ),
                }}
            >
                <img src="/images/land-entire-01-shadow.png" alt="land-01-active" />
                {route1.locked && <Lock className={styles.lock} active={activeLand === 2} />}
            </animated.div>

            <animated.div
                className={classnames(styles.land, styles.land2, styles.active)}
                style={{
                    opacity: land2Styles.activeOpacity,
                    transform: land2Styles.translate.to(
                        (x, y, scaleX, scaleY, rotate) =>
                            `translate(${x}%, ${y}%) scale(${scaleX}, ${scaleY}) rotate(${rotate}deg)`,
                    ),
                }}
            >
                <img src="/images/land-entire-02.png" alt="land-02-active" />
                {route2.locked && <Lock className={styles.lock} active={activeLand === 2} />}
            </animated.div>
            <animated.div
                className={classnames(styles.land, styles.land2, styles.shadow)}
                style={{
                    opacity: land2Styles.shadowOpacity,
                    transform: land2Styles.translate.to(
                        (x, y, scaleX, scaleY, rotate) =>
                            `translate(${x}%, ${y}%) scale(${scaleX}, ${scaleY}) rotate(${rotate}deg)`,
                    ),
                }}
            >
                <img src="/images/land-entire-02-shadow.png" alt="land-02-active" />
            </animated.div>

            <animated.div
                className={classnames(styles.land, styles.land3, styles.active)}
                style={{
                    opacity: land3Styles.activeOpacity,
                    transform: land3Styles.translate.to(
                        (x, y, scaleX, scaleY, rotate) =>
                            `translate(${x}%, ${y}%) scale(${scaleX}, ${scaleY}) rotate(${rotate}deg)`,
                    ),
                }}
            >
                <img src="/images/land-entire-03.png" alt="land-03-active" />
                {route3.locked && <Lock className={styles.lock} active={activeLand === 3} />}
            </animated.div>
            <animated.div
                className={classnames(styles.land, styles.land3, styles.shadow)}
                style={{
                    opacity: land3Styles.shadowOpacity,
                    transform: land3Styles.translate.to(
                        (x, y, scaleX, scaleY, rotate) =>
                            `translate(${x}%, ${y}%) scale(${scaleX}, ${scaleY}) rotate(${rotate}deg)`,
                    ),
                }}
            >
                <img src="/images/land-entire-03-shadow.png" alt="land-03-active" />
            </animated.div>

            <div
                className={classnames(styles.arrow, styles.arrowRight)}
                onClick={() => {
                    if (activeLand === 3) {
                        onChange(1);
                    } else {
                        onChange((activeLand + 1) as 2 | 3);
                    }
                }}
            >
                <img src="/images/arrow.svg" alt="arrow" />
            </div>
            <div
                className={classnames(styles.arrow, styles.arrowLeft)}
                onClick={() => {
                    if (activeLand === 1) {
                        onChange(3);
                    } else {
                        onChange((activeLand - 1) as 1 | 2);
                    }
                }}
            >
                <img src="/images/arrow.svg" alt="arrow" />
            </div>
        </div>
    );
};

export default LandSlider;
