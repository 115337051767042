import classnames from 'classnames';

import PageLayout from '@/components/organisms/PageLayout';
import Title from '@/components/atoms/Title';
import Button from '@/components/atoms/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';

import { SHOP_ABOUTRESONACE } from '@/utils/links';

import styles from './index.module.css';

export interface LayoutAboutResonanceProp {
    className?: string;
    active?: boolean;
}

const LayoutAboutResonance: React.FC<LayoutAboutResonanceProp> = ({ className, active }) => (
    <PageLayout active={active}>
        <div className={classnames(styles.layoutAboutLove, className)}>
            <Title className={styles.title}>共聲電台</Title>

            <>
                <section className={styles.intro}>
                    繁忙的工作和生活，旅人們已經多久沒有好好停下來聆聽各種聲響發出的頻率呢？
                    <br />
                    <br />
                    心跳的頻率、呼吸的頻率、腳步的頻率⋯⋯在自然界中，還有好多有趣的聲音頻率等待大家去探索。在共聲島上有一座由自然環境、島民與旅人共創的聲音電台。凡是來到電台的旅人，將獲得一台虛擬收音機，它將會引導旅人感應與接收共聲電台的聲音訊號。{' '}
                    <br />
                    <br />
                    請將這台收音機拿在胸前，像使用指南針一樣，保持螢幕朝上、朝前，自由地行走在「共聲電台」區域，即將聽見一些破碎、不完整、失真、怪異的雜音，以及呢喃的字句。隨著身體轉到不同方向、仔細感受耳機裡聲音的方位，讓聲音保持在身體中心，朝著該方向前進，直到清晰的聲音浮現。每段聲音都結合了不同的地景聲響、動物鳴叫以及島民創作的音樂，聆聽完畢之後，旅人也能將自己的聲音錄製於共聲島電台裡，獲得屬於自己的一段聲音，成為共聲島的一部分。
                    <br />
                    <br />
                    邀請旅人們來到共聲電台，透過尋覓、感知、沈浸於不同的聲音頻率，調節生活的頻率。
                </section>

                <div className={styles.itemWrapper}>
                    <div className={styles.item}>
                        <div className={styles.name}>體驗時長</div>
                        <div className={classnames(styles.value, styles.digital)}>約 60 分鐘</div>
                    </div>

                    <div className={styles.item}>
                        <div className={styles.name}>地點</div>
                        <div className={classnames(styles.value, styles.digital)}>
                            古城北門至東門一帶 <br />
                            的城北里
                        </div>
                    </div>
                </div>

                <a
                    className={styles.btn}
                    href={SHOP_ABOUTRESONACE}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                        pointerEvents: 'none',
                    }}
                >
                    <Button disabled>
                        <span className={styles.icon} style={{ marginRight: 8 }}>
                            <FontAwesomeIcon icon={faScrewdriverWrench} />
                        </span>
                        <span>島民籌備中</span>
                    </Button>
                </a>
            </>
        </div>
    </PageLayout>
);

export default LayoutAboutResonance;
