import classnames from 'classnames';
import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScrewdriverWrench, faUnlockKeyhole } from '@fortawesome/free-solid-svg-icons';
import { useWindowSize } from '@/utils/hooks';
import { routeList } from '@/utils/data';

import Button from '@/components/atoms/Button';

import styles from './index.module.css';

export interface RouteIntroProp {
    className?: string;
    route: 1 | 2 | 3;
    onClickMore?: (route: string) => void;
}

const RouteIntro: React.FC<RouteIntroProp> = ({ className, route, onClickMore = () => {} }) => {
    const data = useMemo(() => {
        return routeList[route - 1];
    }, [route]);

    const { winWidth } = useWindowSize();

    return (
        <div className={classnames(styles.routeIntro, className)}>
            <div className={styles.container}>
                <div className={styles.left}>
                    <div className={styles.title}>{data.title}</div>
                    <div className={classnames(styles.content, !data.locked && styles.withMore)}>
                        {data.content}
                    </div>
                    {winWidth > 600 && (
                        <div
                            className={classnames(styles.more)}
                            onClick={() => {
                                onClickMore(data.key);
                            }}
                        >
                            了解更多
                        </div>
                    )}
                </div>
                <div className={styles.right}>
                    <div className={styles.infoWrapper}>
                        {data.infos.map(({ label, value }) => (
                            <div key={label} className={styles.infoItem}>
                                {winWidth > 600 && (
                                    <div className={classnames(styles.label)}>{label}</div>
                                )}
                                {winWidth <= 600 && (
                                    <div className={classnames(styles.label, styles.mobile)}>
                                        {label}
                                    </div>
                                )}
                                <div className={styles.value}>{value}</div>
                            </div>
                        ))}
                    </div>

                    <Button
                        className={classnames(styles.btn)}
                        disabled={data.locked}
                        hoverType="color"
                        onClick={() => {
                            if (!data.locked) {
                                window.open(data.link, '_blank');
                            }
                        }}
                    >
                        <span className={styles.icon}>
                            <FontAwesomeIcon
                                icon={data.locked ? faScrewdriverWrench : faUnlockKeyhole}
                            />
                        </span>
                        <span>{data.locked ? '島民籌備中' : '解鎖路線'}</span>
                    </Button>
                    {winWidth <= 600 && (
                        <div
                            className={classnames(styles.more)}
                            onClick={() => {
                                onClickMore(data.key);
                            }}
                        >
                            了解更多
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RouteIntro;
