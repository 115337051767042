import classnames from 'classnames';

import PageLayout from '@/components/organisms/PageLayout';
import Title from '@/components/atoms/Title';
import Button from '@/components/atoms/Button';

import styles from './index.module.css';
import { UNLOCK_ABOUTDESTINY } from '@/utils/links';

export interface LayoutAboutDestinyProp {
    className?: string;
    active?: boolean;
}

const LayoutAboutDestiny: React.FC<LayoutAboutDestinyProp> = ({ className, active }) => (
    <PageLayout active={active}>
        <div className={classnames(styles.layoutAboutLove, className)}>
            <Title className={styles.title}>限時轉運站</Title>

            <>
                <section className={styles.intro}>
                    「限時轉運站」是個以恆春老街為背景的真人互動式數位體驗，故事開始於一位生活不順遂的台北男子，特地南下至恆春尋找網戀三個月的女友
                    Tiffany。男主角帶著滿腔期待，踏上這條前所未有的尋愛之路。隨著他深入恆春老街和收集各種線索，逐步揭開
                    Tiffany 的真正樣貌⋯⋯
                    這條路線將帶著各位深入探索恆春的風土人情，與在地店家互動，收集獨特的圖鑑。透過旅人的選擇，進而影響故事的最終結局；每次的選擇都至關重要，因為，你的決定將決定故事的走向！
                    <br />
                    <br />
                    邀請各位旅人來揭開 Tiffany
                    的神秘面紗，同時將發現老街店家各自擁有的獨特個性與故事。無論是一個人玩，或是與朋友一同挑戰，這條路線都能帶來不同的樂趣與驚喜。出發吧！來場轉變命運的冒險之旅！
                </section>

                <div className={styles.itemWrapper}>
                    <div className={styles.item}>
                        <div className={styles.name}>開放時間</div>
                        <div className={classnames(styles.value, styles.digital)}>
                            2024.10.07 - 2024.10.20
                            <br />
                            （後續將不定期開放）
                        </div>
                    </div>

                    <div className={styles.item}>
                        <div className={styles.name}>體驗時長</div>
                        <div className={classnames(styles.value, styles.digital)}>約 60 分鐘</div>
                    </div>

                    <div className={styles.item}>
                        <div className={styles.name}>地點</div>
                        <div className={classnames(styles.value, styles.digital)}>恆春老街</div>
                    </div>
                </div>

                <a
                    className={styles.btn}
                    href={UNLOCK_ABOUTDESTINY}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button>解鎖路線</Button>
                </a>
            </>
        </div>
    </PageLayout>
);

export default LayoutAboutDestiny;
